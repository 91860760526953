import React from 'react';
import Contact from "./Contact";
import {Link} from "react-router-dom";

export default function NotFound() {

    const quotes = [
        "To breee or not to breee ?",
        "Do you love onions ? We do !",
        "Want more blasts ?",
        "Book us, we can cook !",
        "Let's breee it",
        "Just breee it",
        "Ready to breee ?",
        "We do not play goregrind !",
        "A real french recipe hides inside \"Traumatic Birth Delights\" booklet",
    ]

    const gif = '<iframe\n' +
        'src = "https://giphy.com/embed/jcxtvm2bsZDH2"\n' +
        'width = "480"\n' +
        'height = "387"\n' +
        'frameBorder = "0"\n' +
        '></iframe>';

    const key = Math.floor(Math.random() * quotes.length);

    return (
        <>
            <div
                id="not-found"
                className="block-container"
            >
                <Link to='/'>
                    <h1>{quotes[key]}</h1>
                </Link>
                <p>&#129365;</p>
                <div dangerouslySetInnerHTML={{__html: gif}} />
                <Contact />
            </div>
        </>
    );
}
