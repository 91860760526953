import React from "react";
import Merch from "./Merch";
import Contact from "./Contact";
import Home from "./Home";
// @ts-ignore
import { HashLink } from 'react-router-hash-link';
import '../App.css';
import Music from "./Music";

export default function PagesContainer() {
    return (
        <>
            <div id="menu">
                <HashLink smooth to="/#home">Home</HashLink>
                <HashLink smooth to="/#Music">Music</HashLink>
                <HashLink smooth to="/#merch">Merch</HashLink>
                <HashLink smooth to="/#contact">Contact</HashLink>
            </div>
            <Home />
            <Music />
            <Merch />
            <Contact />
        </>
    );
}
