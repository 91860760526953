import React from 'react';
import { Route, Routes } from 'react-router-dom'
import NotFound from "./pages/NotFound";
import PagesContainer from "./pages/PagesContainer";

function App() {
    return (
        <Routes>
            <Route path="/" Component={PagesContainer} />
            <Route path="*" element={<NotFound />} />
       </Routes>
    )
}

export default App;
