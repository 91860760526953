import React from 'react';
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import spotify from "../assets/spotify.png";
import bandcamp from "../assets/bandcamp.png";
import tiktok from "../assets/tiktok.png";
import {Link} from "react-router-dom";
import {SocialNetworkItem} from "../types/SocialNetworkItem.type";

export default function Contact() {

    const SocialNetworkItems: SocialNetworkItem[] = [
        {
            imageUrl: facebook,
            link: 'https://www.facebook.com/devourthefetus',
            label: 'Devour The Fetus Facebook',
        },
        {
            imageUrl: instagram,
            link: 'https://www.instagram.com/devourthefetus_official',
            label: 'Devour The Fetus Instagram',
        },
        {
            imageUrl: spotify,
            link: 'https://open.spotify.com/artist/4e0VSpGHRWALueHhc6lGiy?si=7KKsSAI2S_6zkELC3u00vQ',
            label: 'Devour The Fetus Spotify',
        },
        {
            imageUrl: bandcamp,
            link: 'https://devourthefetus.bandcamp.com/album/ocean-of-fetal-abominations',
            label: 'Devour The Fetus Bandcamp',
        },
        {
            imageUrl: tiktok,
            link: 'https://www.tiktok.com/@devourthefetus',
            label: 'Devour The Fetus Tiktok',
        }
    ]

    return (
        <>
            <div
                id="contact"
                className="block-container"
            >
                <h1>Book us</h1>
                {
                    SocialNetworkItems.map((item: SocialNetworkItem) => (
                        <Link
                            to={item.link}
                            target="_blank"
                        >
                            <img src={item.imageUrl} alt={item.label} />
                        </Link>
                    ))
                }
            </div>
        </>
    );
}
