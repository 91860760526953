import React from "react";
import logo from "../assets/logo-hd.png";
import cover from "../assets/cover.jpg";

export default function Home() {
        return (
        <>
            <div
                id="home"
                className="block-container"
            >
                <img src={logo} id="logo" alt="Devour The Fetus logo" />
                <div id="cover">
                    <h1>Ocean Of Fetal Abominations</h1>
                    <h2>NEW EP OUT NOW !</h2>
                </div>
            </div>
        </>
    );
}
