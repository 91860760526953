import React from 'react';
import redAbominationShirtLD from '../assets/red-abomination-shirt.jpg';
import redAbominationShirtHD from '../assets/red-abomination-shirt-hd.jpg';
import blackShirtLD from '../assets/black-shirt.jpg';
import blackShirtHD from '../assets/black-shirt-hd.jpg';
import sweatLD from '../assets/sweat.jpg';
import sweatHD from '../assets/sweat-hd.jpg';
import traumaticLD from '../assets/traumatic-tiny.jpg';
import traumaticHD from '../assets/traumatic-hd.jpg';
import traumaticShirt from '../assets/traumatic-shirt.jpg';
import oceanOfFetalAbominationsEP from '../assets/oceanOfFetalAbominationsEP.jpg';
import oceanOfFetalAbominationsEPHD from '../assets/oceanOfFetalAbominationsEPHD.jpg';
import seasonOfAbominationsBlackShirt from '../assets/Season-Of-Abominations---black-shirt-pack.jpg';
import seasonOfAbominationsBlackShirtHD from '../assets/Season-Of-Abominations---black-shirt-packHD.jpg';
import seasonOfAbominationsRedShirt from '../assets/Season-Of-Abominations--red-pack.jpg';
import seasonOfAbominationsRedShirtHD from '../assets/Season-Of-Abominations--red-packHD.jpg';
import seasonOfAbominationsHoodie from '../assets/Season-Of-Abominations--hoodie-pack.jpg';
import seasonOfAbominationsHoodieHD from '../assets/Season-Of-Abominations--hoodie-packHD.jpg';

// @ts-ignore
import Modal from 'react-modal';
import {MerchItem} from "../types/MerchItem.type";

export default function Merch() {
    const oceanOfFetalAbominationsMerch: MerchItem[] = [
        {
            ld: oceanOfFetalAbominationsEP,
            hd: oceanOfFetalAbominationsEPHD,
            label: 'Ocean Of Fetal Abominations EP, 3€',
            alt: 'Ocean Of Fetal Abominations EP, 3€',
            paypalButton: '<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">\n' +
                '<input type="hidden" name="cmd" value="_s-xclick" />\n' +
                '<input type="hidden" name="hosted_button_id" value="VNRNEUQG3UHUG" />\n' +
                '<input type="hidden" name="currency_code" value="EUR" />\n' +
                '<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" title="PayPal, votre réflexe sécurité pour payer en ligne." alt="Ajouter au panier" />\n' +
                '</form>'
        },
        {
            ld: seasonOfAbominationsBlackShirt,
            hd: seasonOfAbominationsBlackShirtHD,
            label: 'Pack Abomination black shirt + EP',
            alt: 'Pack Abomination black shirt + EP',
            paypalButton: '<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">\n' +
                '  <input type="hidden" name="cmd" value="_s-xclick" />\n' +
                '  <input type="hidden" name="hosted_button_id" value="PUU8FAHZX8V7W" />\n' +
                '  <table align="center">\n' +
                '    <tr>\n' +
                '      <td>\n' +
                '        <input type="hidden" name="on0" value="Sizes"/>\n' +
                '        Sizes\n' +
                '      </td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '      <td>\n' +
                '        <select name="os0">\n' +
                '          <option value="S unisex">\n' +
                '            S unisex 18,00 EUR\n' +
                '          </option>\n' +
                '          <option value="M unisex">\n' +
                '            M unisex 18,00 EUR\n' +
                '          </option>\n' +
                '          <option value="L unisex">\n' +
                '            L unisex 18,00 EUR\n' +
                '          </option>\n' +
                '          <option value="XL unisex">\n' +
                '            XL unisex 18,00 EUR\n' +
                '          </option>\n' +
                '          <option value="XXL unisex">\n' +
                '            XXL unisex 18,00 EUR\n' +
                '          </option>\n' +
                '        </select>\n' +
                '      </td>\n' +
                '    </tr>\n' +
                '  </table>\n' +
                '  <input type="hidden" name="currency_code" value="EUR" />\n' +
                '  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" title="PayPal, votre réflexe sécurité pour payer en ligne." alt="Ajouter au panier" />\n' +
                '</form>'
        },
        {
            ld: seasonOfAbominationsRedShirt,
            hd: seasonOfAbominationsRedShirtHD,
            label: 'Pack Abomination red shirt + EP',
            alt: 'Pack Abomination red shirt + EP',
            paypalButton: '<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">\n' +
                '  <input type="hidden" name="cmd" value="_s-xclick" />\n' +
                '  <input type="hidden" name="hosted_button_id" value="96TC9TWLR6SQ2" />\n' +
                '  <table align="center">\n' +
                '    <tr>\n' +
                '      <td>\n' +
                '        <input type="hidden" name="on0" value="Sizes"/>\n' +
                '        Sizes\n' +
                '      </td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '      <td>\n' +
                '        <select name="os0">\n' +
                '          <option value="S unisex">\n' +
                '            S unisex €18.00 EUR\n' +
                '          </option>\n' +
                '          <option value="M unisex">\n' +
                '            M unisex €18.00 EUR\n' +
                '          </option>\n' +
                '          <option value="L unisex">\n' +
                '            L unisex €18.00 EUR\n' +
                '          </option>\n' +
                '          <option value="XL unisex">\n' +
                '            XL unisex €18.00 EUR\n' +
                '          </option>\n' +
                '          <option value="XXL unisex">\n' +
                '            XXL unisex €18.00 EUR\n' +
                '          </option>\n' +
                '        </select>\n' +
                '      </td>\n' +
                '    </tr>\n' +
                '  </table>\n' +
                '  <input type="hidden" name="currency_code" value="EUR" />\n' +
                '  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Add to Cart" />\n' +
                '</form>'
        },
        {
            ld: seasonOfAbominationsHoodie,
            hd: seasonOfAbominationsHoodieHD,
            label: 'Pack Abomination hoodie + EP',
            alt: 'Pack Abomination hoodie + EP',
            paypalButton: '<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">\n' +
                '  <input type="hidden" name="cmd" value="_s-xclick" />\n' +
                '  <input type="hidden" name="hosted_button_id" value="ZBCC5LHVBQ6DU" />\n' +
                '  <table align="center">\n' +
                '    <tr>\n' +
                '      <td>\n' +
                '        <input type="hidden" name="on0" value="Sizes"/>\n' +
                '        Sizes\n' +
                '      </td>\n' +
                '    </tr>\n' +
                '    <tr>\n' +
                '      <td>\n' +
                '        <select name="os0">\n' +
                '          <option value="S unisex">\n' +
                '            S unisex 38,00 EUR\n' +
                '          </option>\n' +
                '          <option value="M unisex">\n' +
                '            M unisex 38,00 EUR\n' +
                '          </option>\n' +
                '          <option value="L unisex">\n' +
                '            L unisex 38,00 EUR\n' +
                '          </option>\n' +
                '          <option value="XL unisex">\n' +
                '            XL unisex 38,00 EUR\n' +
                '          </option>\n' +
                '          <option value="XXL unisex">\n' +
                '            XXL unisex 38,00 EUR\n' +
                '          </option>\n' +
                '        </select>\n' +
                '      </td>\n' +
                '    </tr>\n' +
                '  </table>\n' +
                '  <input type="hidden" name="currency_code" value="EUR" />\n' +
                '  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" title="PayPal, votre réflexe sécurité pour payer en ligne." alt="Ajouter au panier" />\n' +
                '</form>'
        },
    ];

    const merchItems: MerchItem[] = [
        {
            ld: traumaticLD,
            hd: traumaticHD,
            label: 'Traumatic Birth Delight\'s album, 10€',
            alt: 'Traumatic Birth Delight\'s album, 10€',
            paypalButton: '<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">\n' +
                '<input type="hidden" name="cmd" value="_s-xclick">\n' +
                '<input type="hidden" name="hosted_button_id" value="RMG5FBR2NSFEJ">\n' +
                '<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal, le réflexe sécurité pour payer en ligne">\n' +
                '<img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1">\n' +
                '</form>'
        },
        {
            ld: traumaticShirt,
            hd: traumaticShirt,
            label: 'Traumatic Birth Delight\'s shirt',
            alt: 'Traumatic Birth Delight\'s shirt',
            paypalButton: '<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">\n' +
                '<input type="hidden" name="cmd" value="_s-xclick">\n' +
                '<input type="hidden" name="hosted_button_id" value="2SBS398PMVP96">\n' +
                '<table align="center">\n' +
                '<tr><td><input type="hidden" name="on0" value="Sizes">Sizes</td></tr><tr><td><select name="os0">\n' +
                '\t<option value="S girly">S girly €15,00 EUR</option>\n' +
                '\t<option value="M man">M man €15,00 EUR</option>\n' +
                '</select> </td></tr>\n' +
                '</table>\n' +
                '<input type="hidden" name="currency_code" value="EUR">\n' +
                '<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal, le réflexe sécurité pour payer en ligne">\n' +
                '<img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1">\n' +
                '</form>'
        },
    ];

    const newMerchItems: MerchItem[] = [
        {
            ld: blackShirtLD,
            hd: blackShirtHD,
            label: 'Abomination - black',
            alt: 'Abomination - black',
            paypalButton: '<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">\n' +
                '<input type="hidden" name="cmd" value="_s-xclick">\n' +
                '<input type="hidden" name="hosted_button_id" value="LDXHSWNPTZ82U">\n' +
                '<table align="center">\n' +
                '<tr><td><input type="hidden" name="on0" value="Sizes">Sizes</td></tr><tr><td><select name="os0">\n' +
                '\t<option value="S unisex">S unisex €15,00 EUR</option>\n' +
                '\t<option value="M unisex">M unisex €15,00 EUR</option>\n' +
                '\t<option value="L unisex">L unisex €15,00 EUR</option>\n' +
                '\t<option value="XL unisex">XL unisex €15,00 EUR</option>\n' +
                '\t<option value="XXL unisex">XXL unisex €15,00 EUR</option>\n' +
                '</select> </td></tr>\n' +
                '</table>\n' +
                '<input type="hidden" name="currency_code" value="EUR">\n' +
                '<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal, le réflexe sécurité pour payer en ligne">\n' +
                '<img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1">\n' +
                '</form>\n'
        },
        {
            ld: sweatLD,
            hd: sweatHD,
            label: 'Never Explain Anything - sweat',
            alt: 'Never Explain Anything - sweat',
            paypalButton: '<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">\n' +
                '<input type="hidden" name="cmd" value="_s-xclick">\n' +
                '<input type="hidden" name="hosted_button_id" value="B4F4525CGV6BA">\n' +
                '<table align="center">\n' +
                '<tr><td><input type="hidden" name="on0" value="Sizes">Sizes</td></tr><tr><td><select name="os0">\n' +
                '\t<option value="S unisex">S unisex €35,00 EUR</option>\n' +
                '\t<option value="M unisex">M unisex €35,00 EUR</option>\n' +
                '\t<option value="L unisex">L unisex €35,00 EUR</option>\n' +
                '\t<option value="XL unisex">XL unisex €35,00 EUR</option>\n' +
                '\t<option value="XXL unisex">XXL unisex €35,00 EUR</option>\n' +
                '</select> </td></tr>\n' +
                '</table>\n' +
                '<input type="hidden" name="currency_code" value="EUR">\n' +
                '<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal, le réflexe sécurité pour payer en ligne">\n' +
                '<img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1">\n' +
                '</form>'
        },
        {
            ld: redAbominationShirtLD,
            hd: redAbominationShirtHD,
            label: 'Abomination - red',
            alt: 'Abomination - red',
            paypalButton: '<form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">\n' +
                '<input type="hidden" name="cmd" value="_s-xclick">\n' +
                '<input type="hidden" name="hosted_button_id" value="P79JHUANVWGJ4">\n' +
                '<table align="center">\n' +
                '<tr><td><input type="hidden" name="on0" value="Sizes">Sizes</td></tr><tr><td><select name="os0">\n' +
                '\t<option value="S unisex">S unisex €15,00 EUR</option>\n' +
                '\t<option value="M unisex">M unisex €15,00 EUR</option>\n' +
                '\t<option value="L unisex">L unisex €15,00 EUR</option>\n' +
                '\t<option value="XL unisex">XL unisex €15,00 EUR</option>\n' +
                '\t<option value="XXL unisex">XXL unisex €15,00 EUR</option>\n' +
                '</select> </td></tr>\n' +
                '</table>\n' +
                '<input type="hidden" name="currency_code" value="EUR">\n' +
                '<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" border="0" name="submit" alt="PayPal, le réflexe sécurité pour payer en ligne">\n' +
                '<img alt="" border="0" src="https://www.paypalobjects.com/fr_FR/i/scr/pixel.gif" width="1" height="1">\n' +
                '</form>'
        }
    ];

    const customStyles = {
        content: {
            backgroundColor: '#AFAFB9',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            margin: 0,
            padding: 5,
            transform: 'translate(-50%, -50%)'
        },
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [itemSelected, setItem] = React.useState<MerchItem>();

    function openModal(item: MerchItem) {
        setIsOpen(true);
        setItem(item);
    }
    function afterOpenModal() {
    }
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {itemSelected !== undefined &&
                    <img src={itemSelected.hd} alt={itemSelected.alt} />
                }
            </Modal>
            <div
                id="merch"
                className="block-container"
            >
                <h3>Ocean Of Fetal Abominations</h3>
                <div className="merch-line">
                    {
                        oceanOfFetalAbominationsMerch.map((item: MerchItem) => (
                            <div className="item">
                                <img
                                    src={item.ld}
                                    alt={item.alt}
                                    onClick={() => openModal(item)}
                                />
                                <p>{item.label}</p>
                                <div dangerouslySetInnerHTML={{__html: item.paypalButton !== undefined ? item.paypalButton : ''}} />
                            </div>
                        ))
                    }
                </div>
                <h3>2023 spring collection</h3>
                <div className="merch-line">
                    {
                        newMerchItems.map((item: MerchItem) => (
                            <div className="item">
                                <img
                                    src={item.ld}
                                    alt={item.alt}
                                    onClick={() => openModal(item)}
                                />
                                <p>{item.label}</p>
                                <div dangerouslySetInnerHTML={{__html: item.paypalButton !== undefined ? item.paypalButton : ''}} />
                            </div>
                        ))
                    }
                </div>
                <h3>Traumatic Birth Delight's</h3>
                <div className="merch-line">
                    {
                        merchItems.map((item: MerchItem) => (
                            <div className="item">
                                <img
                                    src={item.ld}
                                    alt={item.alt}
                                    onClick={() => openModal(item)}
                                />
                                <p>{item.label}</p>
                                <div dangerouslySetInnerHTML={{__html: item.paypalButton !== undefined ? item.paypalButton : ''}} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}
