import React from 'react';

export default function Music() {
    return (
        <>
            <div
                id="music"
                className="block-container"
            >
                <iframe
                    id="youtube-clip"
                    width="912"
                    height="513"
                    src="https://www.youtube.com/embed/AY67FgaNuVU?si=xGBxXaOFm5cFbs4D"
                    title="Ocean Of Fetal Abominations"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                >
                </iframe>
                <iframe
                    id="bandcam_player"
                    style={{border: 0, width: 400, height: 120}}
                    src="https://bandcamp.com/EmbeddedPlayer/album=1509258424/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless>
                    <a href="https://devourthefetus.bandcamp.com/album/ocean-of-fetal-abominations">Ocean Of Fetal Abominations by Devour the fetus</a>
                </iframe>
            </div>
        </>
    );
}
